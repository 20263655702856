<template>
  <NavBar/>
  <div id="content">
    <router-view/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
@import "assets/styles/bootstrap.css";
@import "assets/styles/bootstrap-theme.css";
@import "assets/styles/bootstrap-select.css";
@import "assets/styles/style.css";

</style>