<template>
  <div>
    <p v-for="(count, item) in topItems" :key="item">{{ item }} 
      {{ count }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    columnName: {
      type: String,
      required: true
    },
    limit: {
      type: Number,
      default: 3
    },
    objects: {
      type: Object
    }
  },
  data() {
    return {

    };
  },
  computed: {
    itemCounts() {
      const counts = {};

      this.objects.forEach(obj => {
        if (Array.isArray(obj[this.columnName])) {
          obj[this.columnName].forEach(item => {
            if (counts[item]) {
              counts[item]++;
            } else {
              counts[item] = 1;
            }
          });
        } else {
          const item = obj[this.columnName];
          if (counts[item]) {
            counts[item]++;
          } else {
            counts[item] = 1;
          }
        }
      });
      return counts;
    },
    sortedItems() {
      return Object.entries(this.itemCounts).sort(([, a], [, b]) => b - a);
    },
    topItems() {
      return Object.fromEntries(this.sortedItems.slice(0, this.limit));
    }
  }
};
</script>