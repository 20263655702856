import { createApp } from 'vue'
import App from './App.vue';
import HomeScreen from './components/HomeScreen.vue'
import DisturbanceOverview from './components/DisturbanceOverview.vue'
import DisturbanceForm from './components/DisturbanceForm.vue'
import MaintenanceOverview from './components/MaintenanceOverview.vue'
import MaintenanceForm from './components/MaintenanceForm.vue'
import LoginScreen from './components/LoginScreen.vue'
import AdministrationPanel from './components/AdministrationPanel.vue'
import Toast from 'vue-toastification';
import EvaluationPanel from './components/EvaluationPanel.vue';
import { validateToken, userIsAdmin } from './components/LoginScreen.vue'

import {createRouter, createWebHashHistory} from 'vue-router';
import 'vue-toastification/dist/index.css';

const routes = [
    { name: 'home', path: '/home', component: HomeScreen },
    { 
        name: 'overview', 
        path: '/overview/:type?/:id?', 
        component: DisturbanceOverview,
        props: (route) => {
            const task = route.params.type || 'all';
            const id = route.params.id || null;
            
            const allowedType = ['all', 'history'];
            let correctedType = allowedType.includes(task) ? task : 'all';
            correctedType = id !== null ? correctedType : 'all'
            return {type: correctedType, id};
        },
    },
    {
        name: 'disturbance-editor',
        path: '/disturbance-editor/:task?/:id?',
        component: DisturbanceForm,
        props: (route) => {
            const task = route.params.task || 'add';
            const id = route.params.id || null;

            // Check if the task is one of the allowed values, otherwise default to "add"
            const allowedTasks = ['add', 'edit', 'clear'];
            let correctedTask = allowedTasks.includes(task) ? task : 'add';
            correctedTask = id !== null ? correctedTask : 'add'
            return {task: correctedTask, id};
        },
    },
    { name: 'maintenance-editor', path: '/maintenance-editor', component: MaintenanceForm },
    { name: 'maintenance-overview', path: '/maintenance-overview', component: MaintenanceOverview },
    { name: 'login', path: '/login', component: LoginScreen },
    { name: 'evaluation', path: '/evaluation', component: EvaluationPanel},
    { name: 'administration', path: '/administration', component: AdministrationPanel }
]

const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: 'active',
    routes,
})

router.beforeEach(async (to, from, next) => {
    const routeExists = router.getRoutes().some(route => route.name === to.name);
    const requiresAuthentication = to.name !== 'login';
    const requiresAdmin = to.name === 'administration' //|| to.name === 'evaluation';

    if (!routeExists) {
        // Wenn die Route nicht existiert, überprüfe, ob der Benutzer authentifiziert ist
        const isValid = await validateToken();
        if (!isValid) {
            next({ name: 'login' });
        } else {
            next({ name: 'home' });
        }
    } else if (requiresAuthentication) {
        const isValid = await validateToken();
        if (!isValid) {
            next({ name: 'login' });
        } else {
            if (await userIsAdmin() === 'False' && requiresAdmin) {
                next({name: 'home'});
            } else {
                next();
            }
        }
    } else {
        const isValid = await validateToken();
        if (isValid && to.name === 'login') {
            next({name: 'home'});
        } else {
            next();
        }
    }
});

createApp(App).use(router).use(Toast).mount('#app')
