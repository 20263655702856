<template>
  <div class="container">
    <div class="add-issue-container">
      <div class="symbols-container heading-box"><span class="material-symbols-outlined head-symbol text-light-red">construction</span><span class="heading-disturbance">Wartung eintragen</span></div>
      <div id="add-issue">
        <div v-if="successMessage">
          <span class="text-green">{{successMessage}}</span>
        </div>
        <div v-else-if="errorMessage">
          <span class="text-red">{{errorMessage}}</span>
        </div>
        <div class="form-info-container">
          <div class="symbols-container">
            <span class="material-symbols-outlined label-symbol">checklist_rtl</span><span class="label-symbol">- Mehrfachauswahl möglich</span>
          </div>
          <div class="symbols-container">
            <span class="text-red label-symbol">*</span><span class="label-symbol"> &emsp;- sind Pflichtfelder</span>
          </div>
        </div>
        <div class="form-group">
          <div class="symbols-container">
            <label for="issue">Betroffener Dienst:</label><span class="text-red label-symbol">*</span><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
          </div>
          <Multiselect v-model="selectedIssues"
                       mode="tags"
                       :options="computedIssues"
                       :groups="true"
                       :groupSelect="false"
                       max="3"
                       id="issue"
          />
        </div>

        <div class="form-group">
          <div class="symbols-container">
            <label for="place">Betroffene Regionen:</label><span class="text-red label-symbol">*</span><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
          </div>
          <Multiselect v-model="selectedRegion"
                       mode="tags"
                       :searchable="true"
                       :options="regions"
                       :show-labels="false"
                       :createOption="true"
                       :appendNewOption="false"
                       :label="'property'"
                       :valueProp="'property'"
                       :trackBy="'property'"
                       :searchStart="true"
                       :noOptionsText="'keine Optionen vorhanden'"
                       :noResultsText="'keine Ergebnis'"
                       id="place"
                       @input="clearElementsRegion"
          />
        </div>

        <div class="sub-properties">
          <div v-if="filteredSubregions.length > 0" class="form-group">
            <div class="symbols-container">
              <label class="text-grey" for="subregion">Stadtteil:</label><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
            </div>
            <Multiselect
                v-model="selectedSubregion"
                mode="tags"
                :searchable="true"
                :options="filteredSubregions"
                :show-labels="false"
                :createOption="true"
                :appendNewOption="false"
                :label="'property'"
                :valueProp="'property'"
                :trackBy="'property'"
                :searchStart="true"
                :noOptionsText="'keine Optionen vorhanden'"
                :noResultsText="'keine Ergebnis'"
                id="subregion"
            />
          </div>

          <div v-if="filteredStreets.length > 0" class="form-group">
            <div class="symbols-container">
              <label class="text-grey" for="street">Straße:</label><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
            </div>
            <Multiselect
                v-model="selectedStreets"
                mode="tags"
                :searchable="true"
                :options="filteredStreets"
                :show-labels="false"
                :createOption="true"
                :appendNewOption="false"
                :label="'property'"
                :valueProp="'property'"
                :trackBy="'property'"
                :searchStart="false"
                :noOptionsText="'keine Optionen vorhanden'"
                :noResultsText="'keine Ergebnis'"
                id="streets"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="description">Überschrift:</label>
          <input class="form-control" name="description" id="description" type="text" v-model="headline">
        </div>
        <div class="form-group">
          <label for="first-encounter">Beginn:</label><span class="text-red label-symbol">*</span>
          <input type="datetime-local" class="form-control " required="required" name="first-encounter" id="first-encounter" v-model="begin">
        </div>
        <div class="form-group">
          <label for="first-encounter">Ende:</label><span class="text-red label-symbol">*</span>
          <input type="datetime-local" class="form-control " required="required" name="first-encounter" id="first-encounter" v-model="end">
        </div>
        <!--div class="form-group-checkbox">
          <input type="checkbox" name="use-table" id="use-table" v-model="useTableInMail">
          <label for="use-table">Wartung in Tabellenübersicht in E-Mail hinzufügen</label>
        </div-->
        <div class="flex-right-container">
          <div class="form-group">
            <button @click="addMaintenance" type="submit" class="btn btn-green">Bestätigen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {post, get} from '../api.js';
import Multiselect from '@vueform/multiselect';

export default {
  name: "MaintenanceForm",
  components: {
    Multiselect,
  },
  data() {
    return {
      issues: [{label:' ', options:['Internet','Telefon','TV','RFT choice']},{label:'', options:['Gesamtausfall']}],
      selectedIssues: [],
      regions: [],
      subregions: [],
      streets: [],
      selectedRegion: [],
      selectedSubregion: [],
      selectedStreets: [],
      begin: '',
      end: '',
      headline: '',
      useTableInMail: true
    }
  },
  created() {
    this.getAllProperties();
  },
  computed: {
    filteredSubregions() {
      if (Array.isArray(this.selectedRegion) && this.selectedRegion.length > 0) {
        const ids = this.selectedRegion.map(region => {
          const foundRegion = this.regions.find(r => r.property === region);
          return foundRegion ? foundRegion.propertyid : null;
        });
        return this.subregions.filter(subregion => ids.includes(subregion.toplevelproperty));
      }
      return [];
    },
    filteredStreets() {
      if (Array.isArray(this.selectedRegion) && this.selectedRegion.length > 0) {
        const ids = this.selectedRegion.map(region => {
          const foundRegion = this.regions.find(r => r.property === region);
          return foundRegion ? foundRegion.propertyid : null;
        });
        return this.streets.filter(street => ids.includes(street.toplevelproperty));
      }
      return [];
    },
    computedIssues() {
      const selectedGroup = this.issues.find(group => this.selectedIssues.some(issue => group.options.includes(issue)));
      return selectedGroup ? this.issues.filter(group => group.label === selectedGroup.label) : this.issues;
    }
  },
  methods: {
    async getAllProperties(){
      try {
        var priority = null;
        if (this.task === 'add')
          priority = 1;
        else if (this.task === 'edit')
          priority = 2;
        var messageResponse;
        if(priority !== null)
          messageResponse = await get('/Disturbance/GetProperties?propertyType=message&priority='+priority);
        else
          messageResponse = await get('/Disturbance/GetProperties?propertyType=message');
        this.messages = messageResponse.data;
        var regionResponse = await get('/Disturbance/GetProperties?propertyType=region');
        this.regions = regionResponse.data;
        var causeResponse;
        if(priority !== null)
          causeResponse = await get('/Disturbance/GetProperties?propertyType=cause&priority='+priority);
        else
          causeResponse = await get('/Disturbance/GetProperties?propertyType=cause');
        this.causes = causeResponse.data;
        var subregionResponse = await get('/Disturbance/GetProperties?propertyType=subregion');
        this.subregions = subregionResponse.data;
        var streetResponse = await get('/Disturbance/GetProperties?propertyType=street');
        this.streets = streetResponse.data;
        var clusterResponse = await get('/Disturbance/GetProperties?propertyType=cluster');
        this.cluster = clusterResponse.data;
        var ubrResponse = await get('/Disturbance/GetProperties?propertyType=ubr');
        this.ubr = ubrResponse.data;
      } catch (error) {
        console.log(error);
      }
    },
    async addMaintenance(){
      try {
        if (this.selectedIssues.length < 1 || this.selectedRegion.length < 1 || this.begin < 1 || this.end < 1) {
          throw new Error("Nicht alle Pflichtfelder wurden ausgefüllt")
        }

        var parameters = {
          Medium: "[" + this.selectedIssues.map(item => `"${item}"`).join(', ') + "]",
          Begin: this.begin,
          End: this.end,
          Region: "[" + this.selectedRegion.map(item => `"${item}"`).join(', ') + "]",
        };
        if (this.headline !== '')
          parameters.headline = this.headline;
        if (this.selectedSubregion.length > 0)
          parameters.subregion = "[" + this.selectedSubregion.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedStreets.length > 0)
          parameters.streets = "[" + this.selectedStreets.map(item => `"${item}"`).join(', ') + "]";
        var response = await post('/Maintenance/AddMaintenance?useTableInMail='+this.useTableInMail, parameters);
        console.log(response.message);
        if (response.status === response.ok) {
          this.$router.push({ path: '/maintenance-overview' }).catch(() => {});
        } else {
          this.errorMessage = "Ein Fehler ist aufgetreten";
        }
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
      }
    }
  },
  
}
</script>

<style scoped>
@import "../assets/styles/add_issue.css";

</style>