<template>
  <div class="home-container">
    <div class="home-info-container">
      <h1 class="display-1 heading">Administration</h1>
      <div v-if="errorMessage">
        <span class="text-red">{{errorMessage}}</span>
      </div>
      <div class="grid2x1fr">
        <div class="admin-container">
          <div class="small-heading">Ursachen</div>
          <div class="admin-content">
            <div class="form-group">
              <label for="cause">Ursache zum Bearbeiten auswählen:</label>
              <Multiselect
                  v-model="selectedCauseId"
                  mode="single"
                  :searchable="true"
                  :options="causes"
                  :custom-label="customLabel"
                  :label="'property'"
                  :valueProp="'propertyid'"
                  :trackBy="'propertyid'"
                  :searchStart="true"
                  :noOptionsText="'keine Optionen vorhanden'"
                  :noResultsText="'keine Ergebnis'"
                  id="cause"
              />
            </div>
            <!--div>
              <select class="form-control dropdown" v-model="selectedCauseId">
                <option hidden disabled :value="null">Auswählen</option>
                <option v-for="cause in causes" :key="cause.propertyid" :value="cause.propertyid">{{ cause.property }}</option>
              </select>
            </div-->
            <p hidden v-if="selectedCause">{{ getSelectedCause() }}</p>
            <label for="selected-cause">Ursache bearbeiten:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="selected-cause" v-model="selectedCauseValue">
              </div>
              <div class="form-group">
                <button @click="ChangeProperty(selectedCauseValue, selectedCauseId, 'cause')" type="submit" class="btn btn-default">Ändern</button>
              </div>
              <div class="form-group">
                <button @click="DeleteProperty((selectedCauseId), 'cause')" type="submit" class="btn btn-default">Löschen</button>
              </div>
            </div>
            <div class="small-heading"></div>
            <label for="new-cause">neue Ursache anlegen:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="new-cause" ref="newCause">
              </div>
              <div class="form-group">
                <button @click="AddProperty(($refs.newCause), 'cause')" type="submit" class="btn btn-default">Hinzufügen</button>
              </div>
            </div>
          </div>
        </div>

        <div class="admin-container">
          <div class="small-heading">Störungsnachrichten</div>
          <div class="admin-content">
            <label for="message">Nachricht zum Bearbeiten auswählen:</label>
            <div class="form-group">
              <Multiselect
                  v-model="selectedMessageId"
                  mode="single"
                  :searchable="true"
                  :options="messages"
                  :label="'property'"
                  :valueProp="'propertyid'"
                  :trackBy="'propertyid'"
                  :searchStart="true"
                  :noOptionsText="'keine Optionen vorhanden'"
                  :noResultsText="'keine Ergebnis'"
                  id="message"
              />
            </div>
            <p hidden v-if="selectedMessage">{{ getSelectedMessage() }}</p>
            <label for="selected-region">Störungsnachricht bearbeiten:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="selected-region" v-model="selectedMessageValue">
              </div>
              <div class="form-group">
                <button @click="ChangeProperty(selectedMessageValue, selectedMessageId, 'message')" type="submit" class="btn btn-default">Ändern</button>
              </div>
              <div class="form-group">
                <button @click="DeleteProperty(selectedMessageId, 'message')" type="submit" class="btn btn-default">Löschen</button>
              </div>
            </div>
            <div class="small-heading"></div>
            <label for="new-region">neue Störungsnachricht anlegen:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="new-cause" ref="newMessage">
              </div>
              <div class="form-group">
                <button @click="AddProperty(($refs.newMessage), 'message')" type="submit" class="btn btn-default">Hinzufügen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="admin-container">
        <div class="small-heading">Regionen</div>
        <div class="grid2x1fr">
          <div class="admin-content">
            <label for="region">Region zum Bearbeiten auswählen:</label>
            <div class="form-group">
              <Multiselect
                  v-model="selectedRegionId"
                  mode="single"
                  :searchable="true"
                  :options="regions"
                  :label="'property'"
                  :valueProp="'propertyid'"
                  :trackBy="'propertyid'"
                  :searchStart="true"
                  :noOptionsText="'keine Optionen vorhanden'"
                  :noResultsText="'keine Ergebnis'"
                  id="region"
              />
            </div>
            <!--div>
              <select class="form-control dropdown" v-model="selectedCauseId">
                <option hidden disabled :value="null">Auswählen</option>
                <option v-for="cause in causes" :key="cause.propertyid" :value="cause.propertyid">{{ cause.property }}</option>
              </select>
            </div-->
            <p hidden v-if="selectedRegion">{{ getSelectedRegion() }}</p>
            <label for="selected-region">Region bearbeiten:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="selected-region" v-model="selectedRegionValue">
              </div>
              <div class="form-group">
                <button @click="ChangeProperty(selectedRegionValue, selectedRegionId, 'region')" type="submit" class="btn btn-default">Ändern</button>
              </div>
              <div class="form-group">
                <button @click="DeleteProperty((selectedRegionId), 'region')" type="submit" class="btn btn-default">Löschen</button>
              </div>
            </div>
            <div class="small-heading"></div>
            <label for="new-region">neue Region anlegen:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="new-region" ref="newRegion">
              </div>
              <div class="form-group">
                <button @click="AddProperty(($refs.newRegion), 'region')" type="submit" class="btn btn-default">Hinzufügen</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedRegionId">
          <div class="small-heading">{{this.selectedRegionValue}}</div>
          <div class="grid2x1fr">
            <div class="admin-content">
              <label for="subregion">Stadtteil zum Bearbeiten auswählen:</label>
              <div class="form-group">
                <Multiselect
                    v-model="selectedSubregionId"
                    mode="single"
                    :searchable="true"
                    :options="filteredSubregions"
                    :label="'property'"
                    :valueProp="'propertyid'"
                    :trackBy="'propertyid'"
                    :searchStart="true"
                    :noOptionsText="'keine Optionen vorhanden'"
                    :noResultsText="'keine Ergebnis'"
                    id="subregion"
                />
              </div>
              <p hidden v-if="selectedSubregion">{{ getSelectedSubregion() }}</p>
              <label for="selected-subregion">Stadtteil bearbeiten:</label>
              <div class="grid5fr1fr1fr">
                <div class="form-group">
                  <input class="form-control" id="selected-subregion" v-model="selectedSubregionValue">
                </div>
                <div class="form-group">
                  <button @click="ChangeProperty(selectedSubregionValue, selectedSubregionId, 'subregion')" type="submit" class="btn btn-default">Ändern</button>
                </div>
                <div class="form-group">
                  <button @click="DeleteProperty((selectedSubregionId), 'subregion')" type="submit" class="btn btn-default">Löschen</button>
                </div>
              </div>
              <div class="small-heading"></div>
              <label for="new-region">neuen Stadtteil anlegen:</label>
              <div class="grid5fr1fr1fr">
                <div class="form-group">
                  <input class="form-control" id="new-subregion" ref="newSubregion">
                </div>
                <div class="form-group">
                  <button @click="AddProperty(($refs.newSubregion), 'subregion', selectedRegionId)" type="submit" class="btn btn-default">Hinzufügen</button>
                </div>
              </div>
            </div>
            
            <div class="admin-content">
              <label for="street">Straße zum Bearbeiten auswählen:</label>
              <div class="form-group">
                <Multiselect
                    v-model="selectedStreetId"
                    mode="single"
                    :searchable="true"
                    :options="filteredStreets"
                    :label="'property'"
                    :valueProp="'propertyid'"
                    :trackBy="'propertyid'"
                    :searchStart="true"
                    :noOptionsText="'keine Optionen vorhanden'"
                    :noResultsText="'keine Ergebnis'"
                    id="street"
                />
              </div>
              <p hidden v-if="selectedStreet">{{ getSelectedStreet() }}</p>
              <label for="selected-street">Straße bearbeiten:</label>
              <div class="grid5fr1fr1fr">
                <div class="form-group">
                  <input class="form-control" id="selected-street" v-model="selectedStreetValue">
                </div>
                <div class="form-group">
                  <button @click="ChangeProperty(selectedStreetValue, selectedStreetId, 'street')" type="submit" class="btn btn-default">Ändern</button>
                </div>
                <div class="form-group">
                  <button @click="DeleteProperty((selectedStreetId), 'street')" type="submit" class="btn btn-default">Löschen</button>
                </div>
              </div>
              <div class="small-heading"></div>
              <label for="new-street">neue Straße anlegen:</label>
              <div class="grid5fr1fr1fr">
                <div class="form-group">
                  <input class="form-control" id="new-street" ref="newStreet">
                </div>
                <div class="form-group">
                  <button @click="AddProperty(($refs.newStreet), 'street', selectedRegionId)" type="submit" class="btn btn-default">Hinzufügen</button>
                </div>
              </div>
            </div>

            <div class="admin-content">
              <label for="ubr">UBR zum Bearbeiten auswählen:</label>
              <div class="form-group">
                <Multiselect
                    v-model="selectedUbrId"
                    mode="single"
                    :searchable="true"
                    :options="filteredUbr"
                    :label="'property'"
                    :valueProp="'propertyid'"
                    :trackBy="'propertyid'"
                    :searchStart="true"
                    :noOptionsText="'keine Optionen vorhanden'"
                    :noResultsText="'keine Ergebnis'"
                    id="ubr"
                />
              </div>
              <p hidden v-if="selectedUbr">{{ getSelectedUbr() }}</p>
              <label for="selected-ubr">UBR bearbeiten:</label>
              <div class="grid5fr1fr1fr">
                <div class="form-group">
                  <input class="form-control" id="selected-ubr" v-model="selectedUbrValue">
                </div>
                <div class="form-group">
                  <button @click="ChangeProperty(selectedUbrValue, selectedUbrId, 'ubr')" type="submit" class="btn btn-default">Ändern</button>
                </div>
                <div class="form-group">
                  <button @click="DeleteProperty((selectedUbrId), 'ubr')" type="submit" class="btn btn-default">Löschen</button>
                </div>
              </div>
              <div class="small-heading"></div>
              <label for="new-ubr">neues UBR anlegen:</label>
              <div class="grid5fr1fr1fr">
                <div class="form-group">
                  <input class="form-control" id="new-ubr" ref="newUbr">
                </div>
                <div class="form-group">
                  <button @click="AddProperty(($refs.newUbr), 'ubr', selectedRegionId)" type="submit" class="btn btn-default">Hinzufügen</button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedUbrId">
            <div class="small-heading">{{this.selectedUbrValue}}</div>
            <div class="grid2x1fr">
              <div class="admin-content">
                <label for="cluster">Cluster zum Bearbeiten auswählen:</label>
                <div class="form-group">
                  <Multiselect
                      v-model="selectedClusterId"
                      mode="single"
                      :searchable="true"
                      :options="filteredCluster"
                      :label="'property'"
                      :valueProp="'propertyid'"
                      :trackBy="'propertyid'"
                      :searchStart="true"
                      :noOptionsText="'keine Optionen vorhanden'"
                      :noResultsText="'keine Ergebnis'"
                      id="cluster"
                  />
                </div>
                <p hidden v-if="selectedCluster">{{ getSelectedCluster() }}</p>
                <label for="selected-cluster">Cluster bearbeiten:</label>
                <div class="grid5fr1fr1fr">
                  <div class="form-group">
                    <input class="form-control" id="selected-cluster" v-model="selectedClusterValue">
                  </div>
                  <div class="form-group">
                    <button @click="ChangeProperty(selectedClusterValue, selectedClusterId, 'cluster')" type="submit" class="btn btn-default">Ändern</button>
                  </div>
                  <div class="form-group">
                    <button @click="DeleteProperty((selectedClusterId), 'cluster')" type="submit" class="btn btn-default">Löschen</button>
                  </div>
                </div>
                <div class="small-heading"></div>
                <label for="new-cluster">neues Cluster anlegen:</label>
                <div class="grid5fr1fr1fr">
                  <div class="form-group">
                    <input class="form-control" id="new-cluster" ref="newCluster">
                  </div>
                  <div class="form-group">
                    <button @click="AddProperty(($refs.newCluster), 'cluster', selectedUbrId)" type="submit" class="btn btn-default">Hinzufügen</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="grid2x1fr">
        <div class="admin-container">
          <div class="small-heading">E-Mail Empfänger Störung</div>
          <div class="admin-content">
            <label for="mail">Empfänger E-Mail zum Bearbeiten auswählen:</label>
            <div class="form-group">
              <Multiselect
                  v-model="selectedMailId"
                  mode="single"
                  :searchable="true"
                  :options="mails"
                  :label="'property'"
                  :valueProp="'propertyid'"
                  :trackBy="'propertyid'"
                  :searchStart="true"
                  :noOptionsText="'keine Optionen vorhanden'"
                  :noResultsText="'keine Ergebnis'"
                  id="mail"
              />
            </div>
            <p hidden v-if="selectedMail">{{ getSelectedMail() }}</p>
            <label for="selected-mail">Empfänger bearbeiten:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="selected-mail" v-model="selectedMailValue">
              </div>
              <div class="form-group">
                <button @click="ChangeProperty(selectedMailValue, selectedMailId, 'mail')" type="submit" class="btn btn-default">Ändern</button>
              </div>
              <div class="form-group">
                <button @click="DeleteProperty((selectedMailId), 'mail')" type="submit" class="btn btn-default">Löschen</button>
              </div>
            </div>
            <div class="small-heading"></div>
            <label for="new-mail">neuen Empfänger hinzufügen:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="new-mail" ref="newMail">
              </div>
              <div class="form-group">
                <button @click="AddProperty(($refs.newMail), 'mail')" type="submit" class="btn btn-default">Hinzufügen</button>
              </div>
            </div>
          </div>
        </div>

        <div class="admin-container">
          <div class="small-heading">E-Mail Empfänger Wartung</div>
          <div class="admin-content">
            <label for="maintenancemail">Empfänger E-Mail zum Bearbeiten auswählen:</label>
            <div class="form-group">
              <Multiselect
                  v-model="selectedMaintenanceMailId"
                  mode="single"
                  :searchable="true"
                  :options="maintenancemails"
                  :label="'property'"
                  :valueProp="'propertyid'"
                  :trackBy="'propertyid'"
                  :searchStart="true"
                  :noOptionsText="'keine Optionen vorhanden'"
                  :noResultsText="'keine Ergebnis'"
                  id="maintenancemail"
              />
            </div>
            <p hidden v-if="selectedMaintenanceMail">{{ getSelectedMaintenanceMail() }}</p>
            <label for="selected-maintenancemail">Empfänger bearbeiten:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="selected-maintenancemail" v-model="selectedMaintenanceMailValue">
              </div>
              <div class="form-group">
                <button @click="ChangeProperty(selectedMaintenanceMailValue, selectedMaintenanceMailId, 'maintancemail')" type="submit" class="btn btn-default">Ändern</button>
              </div>
              <div class="form-group">
                <button @click="DeleteProperty((selectedMaintenanceMailId), 'maintenancemail')" type="submit" class="btn btn-default">Löschen</button>
              </div>
            </div>
            <div class="small-heading"></div>
            <label for="new-maintenancemail">neuen Empfänger hinzufügen:</label>
            <div class="grid5fr1fr1fr">
              <div class="form-group">
                <input class="form-control" id="new-maintenancemail" ref="newMaintenanceMail">
              </div>
              <div class="form-group">
                <button @click="AddProperty(($refs.newMaintenanceMail), 'maintenancemail')" type="submit" class="btn btn-default">Hinzufügen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from '../api.js';
import Multiselect from '@vueform/multiselect';
export default {
  name: "AdministrationPanel",
  components: {
    Multiselect,
  },
  data() {
    return {
      errorMessage: '',
      regions: [],
      subregions: [],
      cluster: [],
      ubr: [],
      streets: [],
      causes: [],
      messages: [],
      mails: [],
      maintenancemails: [],
      selectedCauseId: null,
      oldSelectedCauseId: null,
      selectedCauseValue: null,
      selectedRegionId: null,
      oldSelectedRegionId: null,
      selectedRegionValue: null,
      selectedMessageId: null,
      oldSelectedMessageId: null,
      selectedMessageValue: null,
      selectedMailId: null,
      oldSelectedMailId: null,
      selectedMailValue: null,
      selectedMaintenanceMailId: null,
      oldSelectedMaintenanceMailId: null,
      selectedMaintenanceMailValue: null,
      selectedStreetId: null,
      oldSelectedStreetId: null,
      selectedStreetValue: null,
      selectedSubregionId: null,
      oldSelectedSubregionId: null,
      selectedSubregionValue: null,
      selectedClusterId: null,
      oldSelectedClusterId: null,
      selectedClusterValue: null,
      selectedUbrId: null,
      oldSelectedUbrId: null,
      selectedUbrValue: null
    };
  },
  created() {
    this.UpdateProperties();
  },
  computed: {
    selectedCause() {
      return this.causes.find(option => option.propertyid === this.selectedCauseId);
    },
    selectedRegion() {
      return this.regions.find(option => option.propertyid === this.selectedRegionId);
    },
    selectedMessage() {
      return this.messages.find(option => option.propertyid === this.selectedMessageId);
    },
    selectedMail() {
      return this.mails.find(option => option.propertyid === this.selectedMailId);
    },
    selectedMaintenanceMail() {
      return this.maintenancemails.find(option => option.propertyid === this.selectedMaintenanceMailId);
    },
    selectedSubregion() {
      return this.subregions.find(option => option.propertyid === this.selectedSubregionId);
    },
    selectedStreet() {
      return this.streets.find(option => option.propertyid === this.selectedStreetId);
    },
    selectedCluster() {
      return this.cluster.find(option => option.propertyid === this.selectedClusterId);
    },
    selectedUbr() {
      return this.ubr.find(option => option.propertyid === this.selectedUbrId);
    },
    filteredSubregions() {
      const foundRegion = this.regions.find(region => region.propertyid === this.selectedRegionId);
      if (foundRegion !== undefined) {
        const id = foundRegion.propertyid;
        return this.subregions.filter(subregion => subregion.toplevelproperty === id);
      }
      return [];
    },
    filteredStreets() {
      const foundRegion = this.regions.find(region => region.propertyid === this.selectedRegionId);
      if (foundRegion !== undefined) {
        const id = foundRegion.propertyid;
        return this.streets.filter(street => street.toplevelproperty === id);
      }
      return [];
    },
    filteredUbr() {
      const foundRegion = this.regions.find(region => region.propertyid === this.selectedRegionId);
      if (foundRegion !== undefined) {
        const id = foundRegion.propertyid;
        return this.ubr.filter(ubr => ubr.toplevelproperty === id);
      }
      return [];
    },
    filteredCluster() {
      const foundUbr = this.ubr.find(ubr => ubr.propertyid === this.selectedUbrId);
      if (foundUbr !== undefined) {
        const id = foundUbr.propertyid;
        return this.cluster.filter(cluster => cluster.toplevelproperty === id);
      }
      return [];
    }
  },
  methods: {
    getSelectedCause() {
      if (this.selectedCauseId !== this.oldSelectedCauseId)
      {
        this.selectedCauseValue = this.selectedCause ? this.selectedCause.property : '';
        this.oldSelectedCauseId = this.selectedCauseId;
      }
    },
    getSelectedRegion() {
      if (this.selectedRegionId !== this.oldSelectedRegionId)
      {
        this.selectedRegionValue = this.selectedRegion ? this.selectedRegion.property : '';
        this.oldSelectedRegionId = this.selectedRegionId;
      }
    },
    getSelectedMessage() {
      if (this.selectedMessageId !== this.oldSelectedMessageId)
      {
        this.selectedMessageValue = this.selectedMessage ? this.selectedMessage.property : '';
        this.oldSelectedMessageId = this.selectedMessageId;
      }
    },
    getSelectedMail() {
      if (this.selectedMailId !== this.oldSelectedMailId)
      {
        this.selectedMailValue = this.selectedMail ? this.selectedMail.property : '';
        this.oldSelectedMailId = this.selectedMailId;
      }
    },
    getSelectedMaintenanceMail() {
      if (this.selectedMaintenanceMailId !== this.oldSelectedMaintenanceMailId)
      {
        this.selectedMaintenanceMailValue = this.selectedMaintenanceMail ? this.selectedMaintenanceMail.property : '';
        this.oldSelectedMaintenanceMailId = this.selectedMaintenanceMailId;
      }
    },
    getSelectedSubregion() {
      if (this.selectedSubregionId !== this.oldSelectedSubregionId)
      {
        this.selectedSubregionValue = this.selectedSubregion ? this.selectedSubregion.property : '';
        this.oldSelectedSubregionId = this.selectedSubregionId;
      }
    },
    getSelectedStreet() {
      if (this.selectedStreetId !== this.oldSelectedStreetId)
      {
        this.selectedStreetValue = this.selectedStreet ? this.selectedStreet.property : '';
        this.oldSelectedStreetId = this.selectedStreetId;
      }
    },
    getSelectedCluster() {
      if (this.selectedClusterId !== this.oldSelectedClusterId)
      {
        this.selectedClusterValue = this.selectedCluster ? this.selectedCluster.property : '';
        this.oldSelectedClusterId = this.selectedClusterId;
      }
    },
    getSelectedUbr() {
      if (this.selectedUbrId !== this.oldSelectedUbrId)
      {
        this.selectedUbrValue = this.selectedUbr ? this.selectedUbr.property : '';
        this.oldSelectedUbrId = this.selectedUbrId;
      }
    },
    UpdateProperties() {
      this.GetMessages();
      this.GetRegions();
      this.GetCauses();
      this.GetMails();
      this.GetMaintenanceMails();
      this.GetSubregions();
      this.GetStreets();
      this.GetCluster();
      this.GetUbr();
    },
    async GetMessages(){
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=message');
        this.messages = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetRegions(){
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=region');
        this.regions = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetCauses() {
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=cause');
        this.causes = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetMails() {
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=mail');
        this.mails = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetMaintenanceMails() {
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=maintenancemail');
        this.maintenancemails = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetSubregions() {
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=subregion');
        this.subregions = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetStreets() {
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=street');
        this.streets = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetCluster() {
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=cluster');
        this.cluster = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async GetUbr() {
      try {
        var response = await get('/Disturbance/GetProperties?propertyType=ubr');
        this.ubr = response.data;
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async AddProperty(inputRef, propertyType, topLevelPropertyId = null) {
      try {
        var response;
        if( topLevelPropertyId !== null)
          response = await post('/Disturbance/AddProperty?property='+inputRef.value+'&propertyType='+propertyType+'&topLevelProperty='+topLevelPropertyId);
        else
          response = await post('/Disturbance/AddProperty?property='+inputRef.value+'&propertyType='+propertyType);
        inputRef.value = '';
        this.UpdateProperties();
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    async ChangeProperty(property, propertyId, propertyType) {
      try {
        if (property.trim() === '') {
          await this.DeleteProperty(propertyId, propertyType);
        } else {
          var response = await post('/Disturbance/ChangeProperty?property='+property+'&propertyId='+propertyId);
          this.UpdateProperties();
          console.log(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async DeleteProperty(propertyId, propertyType) {
      try {
        var response = await post('/Disturbance/DeleteProperty?propertyid='+propertyId);
        if (propertyType === 'cause')
          this.selectedCauseId = null;
        else if (propertyType === 'message')
          this.selectedMessageId = null;
        else if (propertyType === 'region')
          this.selectedRegionId = null;
        else if (propertyType === 'street')
          this.selectedStreetId = null;
        else if (propertyType === 'cluster')
          this.selectedClusterId = null;
        else if (propertyType === 'ubr')
          this.selectedUbrId = null;
        else if (propertyType === 'mail')
          this.selectedMailId = null;
        else if (propertyType === 'maintenancemail')
          this.selectedMaintenanceMailId = null;
        this.UpdateProperties();
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>


<style>
@media (min-width: 800px) {
  .grid2x1fr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

@media (max-width: 800px) {
  .grid2x1fr {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}

@media (min-width: 1200px) {
  .grid3x1fr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}

@media (max-width: 1200px) {
  .grid3x1fr {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}

.admin-container {
  margin: 30px 0 0 0;
  background-color: #fff;
  padding: 20px 0 20px 0;
}

.admin-content {
  padding: 30px 40px 0 40px;
}

.home-info-container {
  padding: 30px 3% 30px 3%;
  grid-area: info;
}

.home-container {
  margin: -20px 0 0 0;
  background-color: #f5faff;
}

.grid5fr1fr1fr {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  grid-gap: 20px;
}

.small-heading {
  font-size: 25px;
  border-bottom: 1px solid #000;
  padding: 5px 0 5px 40px;
  margin-bottom: 20px;
}
</style>