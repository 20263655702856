var basePath = process.env.VUE_APP_BASE_PATH;
export async function post(url, data) {
    try {
        const options = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
        };
        const response = await fetch(basePath + url, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        //console.log('Response Data:', responseData);
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function get(url) {
    try {
        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };
        const response = await fetch(basePath + url, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        //console.log('Response Data:', responseData);
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

/*export async function postWithoutAuth(url, data) {
    try {
        const options = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
        };
        const response = await fetch(basePath + url, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}*/