<template>
  <div class="home-container">
    <div class="info-container">
      <h1>Interner RFT Kabel Störungs- und Wartungsarbeiten-Melder</h1>
      <p class="lead info-text">Melden Sie hier eine Störung/Wartung und informieren Sie, automatisch alle betroffene Bereiche.</p>
      <div class="grid2x1fr">
        <router-link to="/disturbance-editor">
          <div class="add-issue-banner">
            <p>Störung melden</p>
          </div>
        </router-link>
        <router-link to="/maintenance-editor">
          <div class="add-issue-banner">
            <p>Wartung eintragen</p>
          </div>
        </router-link>
      </div>
    </div>
    
    <div class="grid1fr">
      <div class="current-issues">
        <h3 class="padding-title">Letzte Störungen</h3>
        <div v-if="disturbances.length < 1" class="no-disturbances symbols-container">
          <span>Es liegen keine Störungsmeldungen vor.</span><span class="material-symbols-outlined text-green head-symbol-small">check_circle</span>
        </div>
        <DisturbanceTable
            :disturbances="disturbances"
            :type="type"
            @edit="editRedirect"
            @clear="clearRedirect"
            @history="showHistory"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DisturbanceTable from '@/components/DisturbanceTable.vue';
import {get} from '../api.js';
export default {
  name: "HomeScreen",
  data() {
    return {
      disturbances: []
    };
  },
  components: {
    DisturbanceTable
  },
  created() {
    this.GetDisturbances();
  },
  methods: {
    formatDisturbances(){
      this.disturbances.forEach(item => {
        item.medium = JSON.parse(item.medium);
        item.region = JSON.parse(item.region);
        item.subregion = JSON.parse(item.subregion);
        item.streets = JSON.parse(item.streets);
        item.cluster = JSON.parse(item.cluster);
        item.ubr = JSON.parse(item.ubr);
        item.mail_sent_to = JSON.parse(item.mail_sent_to);
      });
    },
    async GetDisturbances(){
      try {
        var response = await get('/Disturbance/GetDisturbances?beginDate=1-1-1&endDate=9999-1-1&solved=false&order=DESC&limit=3');
        this.disturbances = response.data;
        this.formatDisturbances();
      } catch (error) {
        console.log(error);
      }
    },
    async editRedirect(id){
      try {
        this.$router.push({ path: '/disturbance-editor/edit/' + id }).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    async clearRedirect(id) {
      try {
        this.$router.push({path: '/disturbance-editor/clear/' + id}).catch(() => {
        });
      } catch (error) {
        console.log(error);
      }
    },
    async showHistory(id){
      try {
        this.$router.push({ path: '/overview/history/' + id }).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>


<style scoped>

.add-issue-banner {
  max-width: 80%;
  min-height: 80px;
  text-align: center;
  /*color: #fff;*/
  color: #000;
  outline: 2px solid;
  outline-color: #000;
  /*background-image: linear-gradient(#175, #1a4);*/
  background-image: linear-gradient(#fff, #ffd);
  font-size: 40px;
  margin-bottom: 50px;
  padding-top: 10px;
  cursor: pointer;
  box-shadow: 3px 3px 3px black;
}

.add-issue-banner:hover {
  color: #000;
  background-image: linear-gradient(#ffe, #feb);
  text-decoration: none !important;
}

.grid2fr1fr {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid1fr {
  display: grid;
}

.grid4x1fr {
  display: grid;
  grid-template-columns: 240px repeat(3, 1fr);
}

h1 {
  font-weight: 600;
}

.home-container {
  margin: -20px 2% 0 2%;
  padding: 30px 10px 0 20px;
  background-color: #f5faff;
  min-height: 100vh;
}

.image-container {
  margin-left: 30px;
}

.info-text {
  margin-right: 5%;
  margin-top: 20px;
  text-align: justify;
}

.medium-image {
  position: relative;
  display:inline-block;
  vertical-align:middle;
  width: 67px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  top: -10px;
}

#nophone {
  display:inline-block;
  vertical-align:middle;
  width: 70px;
  height: 70px;
}

#nointernet {
  display:inline-block;
  vertical-align:middle;
  width: 70px;
  height: 70px;
  margin-left: 20px;
}

#notelevision {
  display:inline-block;
  vertical-align:middle;
  width: 70px;
  height: 70px;
  margin-left: 20px;
}

.padding-title {
  padding-left: 15px;
}

.table-default {
  padding-bottom: 25px;
  padding-top: 5px;
  width: 100%;
}

.time-window-select {
  width: 100%;
  grid-gap: 30px;
  display: grid;
  margin-left: 80px;
  grid-template-columns: 2fr 2fr;
}

@media (min-width: 1200px) {
  .current-issues {
    margin: 0 10%;
  }

  .info-container {
    margin: 0 10% 30px 10%;
    padding-left: 15px;
  }
}


@media (max-width: 1200px) {
  .info-container {
    padding: 20px 20px 0 40px;
  }

  .grid2fr1fr {
    grid-template-columns: 1fr;
  }
}


@media (max-width: 600px) {
  div {
    max-width: 100%;
  }

  #notelevision, #nophone, #nointernet {
    width: 50px;
    height: 50px;
  }

  .home-info-container {
    padding: 10px;
  }

  .current-issues {
    margin: 30px 5px;
  }

  .time-window-select {
    margin-left: 5%;
  }

}

@media (max-width: 768px) {
  .grid4x1fr {
    display: grid;
    grid-template-columns: 240px 1fr;
  }
  
  .home-container {
    margin: -20px 0 0 0;
  }
}

@media (max-width: 450px) {
  .grid4x1fr {
    display: grid;
    grid-template-columns: 1fr;
  }
}
</style>