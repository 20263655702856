<template>
  <div class="overview-container">
    <div class="overview-head">
      <div class="flexbox">
        <div class="grid1fr3fr">
          <div>
            <label class="select-small-label" for="month">Monat:</label>
          </div>
          <Multiselect
              class="select-small"
              v-model="selectedMonth"
              mode="single"
              :options="months"
              :label="'name'"
              :valueProp="'value'"
              :trackBy="'name'"
              id="month"
          />
        </div>
        <div class="grid1fr3fr">
          <div>
            <label class="select-small-label" for="type">Jahr:</label>
          </div>
          <Multiselect
              class="select-small"
              v-model="selectedYear"
              mode="single"
              :options="generatedYears"
              id="month"
          />
        </div>
        <div class="form-group">
          <button @click="getDisturbancesByMonthAndYear" type="submit" class="btn-evaluation">Auswählen</button>
        </div>
        <div v-if="filteredDisturbances.length > 0" class="form-group">
          <!--button @click="exportAsCsv" type="submit" class="btn-evaluation">Auswertung in CSV speichern</button-->
        </div>
      </div>
      <div class="filter-box">
        <label class="evaluation-heading">Weitere Filter setzen</label>
        <div class="flexbox">
          <div class="grid1fr3fr">
            <label class="select-small-label" for="type">Dienst:</label>
            <input class="select-small" type="text" v-model="filterMedium"/>
          </div>
          <div class="grid1fr3fr">
            <label class="select-small-label" for="type">Region:</label>
            <input class="select-small" type="text" v-model="filterRegion"/>
          </div>
          <div class="grid1fr3fr">
            <label class="select-small-label" for="type">Ursache:</label>
            <input class="select-small" type="text" v-model="filterCause"/>
          </div>
        </div>
      </div>
      
    </div>
    <div v-if="filteredDisturbances.length > 0">
      <h2 class="display-1 overview-title">Auswertung für diesen Zeitraum</h2>
      <!--div class="flexbox"-->
      <div class="grid3x1fr-notfix">
        <div class="evaluation-box">
          <div class="grid2x1fr">
            <label>Anzahl Störungen:</label><p>{{ filteredDisturbances.length }}</p>
          </div>

        </div>
        <div class="evaluation-box">
          <div class="grid2x1fr">
            <label>Längste Dauer:</label><p>{{ longestDuration }}</p>
          </div>
          <div class="grid2x1fr">
            <label>Kürzeste Dauer:</label><p>{{ shortestDuration }}</p>
          </div>
          <div class="grid2x1fr">
            <label>Durchschnittliche Dauer:</label><p>{{ averageDuration }}</p>
          </div>
        </div>

        <div class="evaluation-box">
          <div class="grid2x1fr">
            <label>Häufigste Ursachen:</label>
            <TopItems columnName="cause" :limit="3" :objects="filteredDisturbances"/>
          </div>
        </div>

        <div class="evaluation-box">
          <div class="grid2x1fr">
            <label>Am häufigsten Betroffene Ortschaft:</label>
            <TopItems columnName="region" :limit="3" :objects="filteredDisturbances"/>
          </div>
        </div>

        <div class="evaluation-box">
          <div class="grid2x1fr">
            <label>Am häufigsten betroffene Dienste:</label>
            <TopItems columnName="medium" :limit="3" :objects="filteredDisturbances"/>
          </div>
        </div>
      </div>

      <h2 class="display-1 overview-title">Alle Störungen in dem Zeitraum</h2>
      <div class="evaluation-table">
        <DisturbanceTable
            :disturbances="filteredDisturbances"
            :type="'all'"
            :isEvaluation="true"
            @history="showHistory"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {get} from '../api.js';
import Multiselect from '@vueform/multiselect';
import DisturbanceTable from '@/components/DisturbanceTable.vue';
import TopItems from '@/components/TopItems.vue';
export default {
  name: "EvaluationPanel",
  components: {
    Multiselect,
    DisturbanceTable,
    TopItems
  },
  data() {
    return {
      selectedMonth: '',
      selectedYear: '',
      filterMedium: '',
      filterRegion: '',
      filterCause: '',
      months: [
        { name: 'Komplettes Jahr', value: '00' }, { name: 'Januar', value: '01' }, { name: 'Februar', value: '02' }, { name: 'März', value: '03' },
        { name: 'April', value: '04' }, { name: 'Mai', value: '05' }, { name: 'Juni', value: '06' },
        { name: 'Juli', value: '07' }, { name: 'August', value: '08' }, { name: 'September', value: '09' },
        { name: 'Oktober', value: '10' }, { name: 'November', value: '11' }, { name: 'Dezember', value: '12' }
      ],
      disturbances: []
    };
  },
  computed: {
    filteredDisturbances() {
      return this.disturbances.filter(disturbance => {
        const matchesText = this.filterMedium
            ? disturbance.medium.includes(this.filterMedium)
            : true;
        const matchesType = this.filterRegion
            ? disturbance.region.includes(this.filterRegion)
            : true;
        const matchesStatus = this.filterCause
            ? disturbance.cause.includes(this.filterCause)
            : true;
        return matchesText && matchesType && matchesStatus;
      });
    },
    longestDuration() {
      let maxDuration = 0;
      this.filteredDisturbances.forEach(obj => {
        const duration = this.calculateDuration(obj.first_encounter, obj.timestamp);
        if (duration > maxDuration && obj.solved === true) {
          maxDuration = duration;
        }
      });
      return this.formatDuration(maxDuration);
    },
    shortestDuration() {
      let minDuration = Infinity;
      this.filteredDisturbances.forEach(obj => {
        const duration = this.calculateDuration(obj.first_encounter, obj.timestamp);
        if (duration < minDuration && obj.solved === true) {
          minDuration = duration;
        }
      });
      return this.formatDuration(minDuration);
    },
    averageDuration() {
      let totalDuration = 0;
      let count = 0;
      this.filteredDisturbances.forEach(obj => {
        if (obj.solved === true) {
          totalDuration += this.calculateDuration(obj.first_encounter, obj.timestamp);
          count++;
        }
      });
      const avgDuration = totalDuration / count;
      return this.formatDuration(avgDuration);
    },
    generatedYears() {
      return Array.from({ length: 2044 - 2024 }, (v, i) => (2024 + i).toString());
    }
  },
  methods: {
    calculateDuration(first_encounter, last_update) {
      const firstEncounterDate = new Date(first_encounter);
      const lastUpdateDate = new Date(last_update);
      return lastUpdateDate - firstEncounterDate;
    },
    formatDuration(duration) {
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const days = Math.floor(duration / (1000 * 60 * 60 * 24));

      return `${days}d, ${hours}h, ${minutes}m`;
    },
    async getDisturbancesByMonthAndYear(){
      try {
        if (this.selectedMonth === '' || this.selectedYear === '') {
          throw new Error("Monat und Jahr auswählen")
        }
        var response = await get('/Disturbance/GetDisturbancesByMonthAndYear?month='+this.selectedMonth+'&year='+this.selectedYear);
        this.disturbances = response.data;
        this.formatDisturbances();
        console.log(this.disturbances);
      } catch (error) {
        console.log(error);
      }
    },
    async exportAsCsv() {
      try {
        let clonedDisturbances = this.filteredDisturbances.map(obj => {
          let clonedObj = JSON.parse(JSON.stringify(obj));
          console.log(clonedObj);
          for (const key in clonedObj) {
            if (Object.prototype.hasOwnProperty.call(clonedObj, key)) {
              if (key === 'solved' || key === 'disturbance_id') {
                delete clonedObj[key];
                continue;
              }
              if (Array.isArray(clonedObj[key]) && clonedObj[key].every(item => typeof item === 'string')) {
                clonedObj[key] = `['${clonedObj[key].join("', '")}']`;
              }
            }
          }
          return clonedObj;
        });
        console.log(clonedDisturbances);
        const response = await fetch(process.env.VUE_APP_BASE_PATH + '/Disturbance/ExportEvaluationDataAsCsv', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(clonedDisturbances)
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'evaluation_data.csv'); 
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    },
    async showHistory(id){
      try {
        this.$router.push({ path: '/overview/history/' + id }).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    formatDisturbances(){
      this.disturbances.forEach(item => {
        item.medium = JSON.parse(item.medium);
        item.region = JSON.parse(item.region);
        item.subregion = JSON.parse(item.subregion);
        item.streets = JSON.parse(item.streets);
        item.cluster = JSON.parse(item.cluster);
        item.ubr = JSON.parse(item.ubr);
        item.mail_sent_to = JSON.parse(item.mail_sent_to);
      });
    }
  }
}
</script>

<style scoped>
@import "../assets/styles/overviews.css";

.btn-evaluation {
  box-shadow: black 1px 1px 1px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid #888;
  padding: 3px 12px;
  font-size: 14px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #ffffff 0%, #e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  text-shadow: 0 1px 0 #fff;
}

.evaluation-box {
  padding: 20px;
  font-size: 18px;
}

.evaluation-heading {
  margin-bottom: 20px;
}

.filter-box {
  padding: 20px 0 0 0;
  border-top: 1px solid #000;
}

.flexbox  {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  margin: 0 0 30px 0;
}

.evaluation-table {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 1268px) {
  .evaluation-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid1fr3fr {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.select-small {
  display: block;
  width: 250px;
  font-size: 18px;
  height: 40px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  margin-right: 60px;
}

.select-small-label {
  font-size: 18px;
  padding-top: 4px;
}

</style>